<template lang="">
    <div class="login_main">
        <div class="Header">
            报废汽车ERP
        </div>
        <div class="main">
           <div class="main_content">

                    <van-field v-model="num"  label="商户编号" placeholder="请输入商户编号" />
                    <van-field v-model="username" label="账号" placeholder="请输入账号" />
                    <van-field v-model="password" label="密码" type="password" placeholder="请输入密码" />
                    <van-field v-model="verify" center clearable label="验证码" placeholder="请输入验证码" >
                    </van-field>
                  
                    <br>
                    <img class="image" :src="imgUrl" alt="" @click="imgshua">
            </div>
            <!-- <div >
              
            </div>
            <div>
                <span>密码</span>:
                <input type="password" v-model="password"> 
            </div>

            <div>
                <span>商户编号</span>:
                <input type="number"  v-model="num"> 
            </div> -->


            <div class="login_btn" @click="register()">
                登录
            </div>

    
        </div>
       

    </div>
</template>
<script>
import {  setCookie ,getCookie} from '../utils/env'
import { Toast } from "vant";
import axios from 'axios'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
      username: "",
      password: "",
      num: "",
      verify:"",
      imgUrl:"",
    };
  },
  created() {
        if(getCookie('token')) this.$router.push('/guide');
        this.imgshua()
    },
  
  methods: {
        imgshua(){
            axios({
                method: "post",
                url: "/index.php/verify/index",
                responseType: "arraybuffer", // 最为关键
            }).then((res)=> {
                this.imgUrl =
                "data:image/jpeg;base64," + this.arrayBufferToBase64(res.data);
            });
        },
        arrayBufferToBase64(buffer) {
            var binary = "";
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },
        lodData(){
              this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                  //console.log(window.location)
                  if(res.data.code == 0){
                       let domain = this.zhuan(res.data.data)
                       let url = window.location.origin
                       //console.log(url)
                      window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
                  }
              })
        },
        zhuan(url){
             let str = url
            let str1 = str.replace('https','')
            str1 = str1.replace('http','')
            let str2 = 'https'+ str1
            return str2
        },
    register() {
        const toast = Toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "加载中..."
     });
        let obj = {'userName':this.username,'password':this.password,'shopId':this.num,'openid':this.$route.query.openId,verify:this.verify}

        let data = encipherMent( JSON.stringify(obj))
        let param = new URLSearchParams();
        param.append("value",data);
        this.$http.post('/index.php/index/login',param).then(res=>{
                let result = {}
                if(res.data.code == -1 || res.data.code == 9){
                    result = res.data
                }else{
                    result = JSON.parse( encryptDecode(res.data))
                }
                console.log(result)
                toast.clear()//清除加载效果
                if(result.code == 0) {
                    setCookie('token', result.data.token, 30)
                    setCookie('H5_userId', result.data.userInfo.id, 30)
                    setCookie('H5_shopId', result.data.userInfo.shopId, 30)
                    window.sessionStorage.setItem('Name',result.data.userInfo.realName)
                    window.localStorage.setItem('shopName',result.data.userInfo.shopName)
                    window.localStorage.setItem('userName',result.data.userInfo.userName)
                    window.localStorage.setItem('realName',result.data.userInfo.realName)
                    window.localStorage.setItem('shopId',result.data.userInfo.shopId)
                    window.localStorage.setItem('userId',result.data.userInfo.id)

                    this.$toast.success("登陆成功")
                    if(this.$route.query.id != null ){
                        this.$router.push({path:"inspect",query:{id:this.$route.query.id,ids:this.$route.query.ids}})
                    }else{
                        this.$router.push('/guide')
                        // setTimeout(()=>{
                        //     this.$router.go(0);
                        // },300)
                    }
                }else {
                    this.imgshua()
                    this.$toast.fail(result.msg)
                }
                //console.log(res.data)
            }).catch(()=>{
                this.imgshua()
                toast.clear()//清除加载效果
            })
        },
  },
  getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }
};
</script>
<style lang="scss" scoped> 
    html{
        height: 100%;
        // background: gainsboro;
    }
    .Header{
        background-color: cadetblue;
        height: 1rem;
        text-align: center;
        line-height: 1rem;
        color: white;
        font-size: 0.4rem;
    }
    .main{
        background-color: white;
        width: 100%;
        height: 5rem;
        margin: 50px 0;
        padding-top: 1rem;
        .main_content{
            width: 80%;
            margin: 0 auto;
        }

    }
    
    
    .login_btn{
        width: 60%;
        height: 0.9rem;
        font-size: 0.5rem;
        text-align: center;
        line-height: 0.9rem;
        margin: 1rem auto;
        background: darkcyan;
        color: white;
        border-radius: 5px;
    }
    .image{
        width: 100%;
        height: 40px;
        
    }
    .van-field__control{
        font-size: 12px !important;
    }
</style>